import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {formatAMPM, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails } from "../../../action/actions";

function OutpatientList(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);

    const header = [
        "S/N",
        "Patient Name",
        "Patient ID",
        "Doctor Name",
        "Doctor ID",
        "Department",
        "Appointment Date",
        "Appointment Time",
        "Status",
        "Checked In On",
        "Action"
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}patient/consultation/outpatient/list`, token)
            .then((result) => {
                console.log(result)
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientName}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.doctorName}</td>
                        <td>{item.doctorID}</td>
                        <td>{item.departmentName}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td>{item.appointmentStatus}</td>
                        <td>{!item.checkInTime ? '--' : formatAMPM(item.checkInTime)}</td>
                        <td className="d-flex">
                            <Link to={`/${props.access?.toLowerCase()}/outpatient/dashboard/${btoa(item.appointmentID)}`}
                                  className={"btn btn-info btn-sm  mr-3"}

                            >
                                Dashboard
                            </Link>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Outpatient"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <h4 className="card-title">Outpatient List</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                        </div>
                                    </div>
                                    <DataTable
                                        tableID="Patient"
                                        header={header}
                                        body={showTable()}
                                        title="Outpatient Report"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OutpatientList);
